<template>
  <div class="payments" v-if="hasPerm('payments.view_credit')">
    <page-header title="Avoirs et remboursements" icon="fa fa-euro" :links="getLinks()">
    </page-header>
    <div ref="printMe">
      <loading-gif :loading-name="loadingName"></loading-gif>
      <div class="sub-header" v-show="initialLoadingDone">
        <b-row>
          <b-col>
            <b>
              <span v-if='!isLoading(loadingName)'>
                <counter-label  :counter="creditsCount" :label="counterLabel" :labelN="counterLabelN">
                </counter-label> -
                Total montant initial {{ totalAmount | currency }} -
                Total montant restant {{ totalRemaining | currency }}
              </span>
            </b>
          </b-col>
        </b-row>
      </div>
      <div v-show="initialLoadingDone" class="no-print">
        <b-row>
          <b-col cols="3">
            <b-form-group
              label="Éléments"
              label-for="status"
              id="status-group"
            >
              <b-form-select
                id="status"
                v-model="selectedStatus"
              >
                <b-form-select-option
                  :value="item"
                  v-for="item in creditsStatus"
                  :key="item.id"
                >
                  {{ item.name }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <date-range-selector
              :free-period="true"
              :init-with-free="[startDate, endDate]"
              @change="onDateRangeChanged">
            </date-range-selector>
          </b-col>
          <b-col cols="3" class="text-right">
            <b-checkbox
              :checked="noPagination"
              :disabled="(pagesCount > 10) || isLoading(loadingName)"
              @change="reloadPagination"
              style="margin-bottom: 5px"
            >
              Tout voir
            </b-checkbox>
            <pagination
              :pages-count="pagesCount"
              :has-next="hasNext"
              :has-previous="hasPrevious"
              @change="onPageChanged($event)"
              css-style="justify-content: flex-end;"
            >
            </pagination>
          </b-col>
        </b-row>
      </div>
      <div class="hide-here">
        <b-row>
          <b-col>
            <span v-if="startDate">À partir du {{ startDate | dateToString }}</span>
            <span v-if="endDate">jusqu'au {{ endDate | dateToString }}</span>
          </b-col>
        </b-row>
      </div>
      <div>
        <b-row>
          <b-col cols="2">
            <b class="small-text">
              Affichage analytiques:
            </b>
          </b-col>
          <b-col cols="3">
            <b class="small-text">filtrer par</b>
          </b-col>
          <b-col cols="5">
          </b-col>
          <b-col cols="2">
            <b class="small-text">trier par</b>
          </b-col>
        </b-row>
      </div>
      <div class="header-bar no-print" v-if="!isLoading(loadingName)">
        <b-row>
          <b-col cols="2">
            <b-form-checkbox id="showAnalyticSource" v-model="showAnalyticSource" inline class="small-check">
              Source
            </b-form-checkbox>
            <b-form-checkbox id="showAnalyticUsage" v-model="showAnalyticUsage" inline class="small-check">
              Utilisation
            </b-form-checkbox>
          </b-col>
          <b-col cols="2">
            <b-form-select class="small-select" v-model="dateFilter" id="dateFilter" @change="onDateFilterChanged()">
              <b-form-select-option :value="1">date de création</b-form-select-option>
              <b-form-select-option :value="2">date d'utilisation</b-form-select-option>
            </b-form-select>
          </b-col>
          <b-col cols="2">
            <b-form-select
              class="small-select"
              v-if="dateFilter === 2"
              v-model="onlyUsageInDateRange"
              id="onlyUsageInDateRange"
              @change="onFilterParamsChanged"
            >
              <b-form-select-option :value="1">tous les usages</b-form-select-option>
              <b-form-select-option :value="2">seulement les usages de la période</b-form-select-option>
            </b-form-select>
          </b-col>
          <b-col cols="2">
            <b-input
              v-show="(pagesCount === 1) && showAnalyticFilter"
              class="small-input"
              v-model="analyticFilter"
              placeholder="filtrer les analytiques"
            >
            </b-input>
          </b-col>
          <b-col cols="2">
          </b-col>
          <b-col cols="2">
            <b-form-select class="small-select" v-model="orderBy" id="order-by" v-show="pagesCount === 1">
              <b-form-select-option :value="0">Anti-Chronologique</b-form-select-option>
              <b-form-select-option :value="1">Chronologique</b-form-select-option>
              <b-form-select-option :value="2">Nom de famille</b-form-select-option>
            </b-form-select>
          </b-col>
        </b-row>
      </div>
      <div class="empty-text" v-if="!isLoading(loadingName) && credits.length === 0">Aucun avoir correspondant</div>
      <div ref="excelTable">
        <table
          class="table table-bordered small-table"
          v-if="!isLoading(loadingName) && credits.length"
        >
          <tr>
            <th colspan="3"></th>
            <th colspan="4" class="analytic-source" v-if="showAnalyticSource">Source</th>
            <th class="analytic-source number" v-if="showAnalyticSource">
              {{ sumSources }}
            </th>
            <th colspan="4" class="analytic-usage" v-if="showAnalyticUsage">Utilisation</th>
            <th class="analytic-usage number" v-if="showAnalyticUsage">
              {{ sumUsages }}
            </th>
          </tr>
          <tr>
            <th>Famille</th>
            <th>Montant initial</th>
            <th>Montant restant</th>
            <th class="analytic-source" v-if="showAnalyticSource">Date</th>
            <th class="analytic-source" v-if="showAnalyticSource">Compte analytique</th>
            <th class="analytic-source" v-if="showAnalyticSource">Année</th>
            <th class="analytic-source" v-if="showAnalyticSource">Compte général</th>
            <th class="analytic-source" v-if="showAnalyticSource">Montant</th>
            <th class="analytic-usage" v-if="showAnalyticUsage">Date</th>
            <th class="analytic-usage" v-if="showAnalyticUsage">Compte analytique</th>
            <th class="analytic-usage" v-if="showAnalyticUsage">Année</th>
            <th class="analytic-usage" v-if="showAnalyticUsage">Compte général</th>
            <th class="analytic-usage" v-if="showAnalyticUsage">Montant</th>
          </tr>
          <tr v-for="item in items" :key="item.id" :class="{ odd: item.odd }" class="credit-line">
            <td v-if="item.credit" :rowspan="item.rowspan">
              <a
                :href="getEntityLink(item.credit.entity)"
                @click.prevent="showEntitySidebar(item.credit.entity)"
                v-if="!item.credit.entity.archived"
              >
                <span class="family-numbers">{{ item.credit.entity.id }}</span>
                {{ item.credit.entity.name }}
              </a>
            </td>
            <td class="number" v-if="item.credit" :rowspan="item.rowspan">
              {{ item.credit.amount|currency }}
            </td>
            <td class="number" v-if="item.credit" :rowspan="item.rowspan">
              {{ item.credit.remainingAmount|currency }}
            </td>
            <td v-if="item.credit && showAnalyticSource" :rowspan="item.rowspan" class="analytic-source">
              {{ item.credit.createdOn | dateToString('DD/MM/YYYY') }}
            </td>
            <td v-if="item.credit && showAnalyticSource" :rowspan="item.rowspan" class="analytic-source">
              <span v-if="item.fromAnalytic">
                {{ item.fromAnalytic.analyticAccount.name }}
              </span>
            </td>
            <td v-if="item.credit && showAnalyticSource" :rowspan="item.rowspan" class="analytic-source">
              <span v-if="item.fromAnalytic">
                {{ item.fromAnalytic.schoolYear.name }}
              </span>
            </td>
            <td v-if="item.credit && showAnalyticSource" :rowspan="item.rowspan" class="analytic-source">
              <span v-if="item.fromAnalytic">
                {{ item.fromAnalytic.generalAccount.name }}
              </span>
            </td>
            <td v-if="item.credit && showAnalyticSource" :rowspan="item.rowspan" class="analytic-source number">
              <span v-if="item.fromAnalytic">
                {{ item.fromAnalytic.amount | currency }}
              </span>
            </td>
            <td :class="item.cssClass" v-if="showAnalyticUsage">
              <span v-if="item.analytic">
                {{ item.analytic.createdOn | dateToString }}
              </span>
            </td>
            <td :class="item.cssClass" v-if="showAnalyticUsage">
              <span v-if="item.analytic">
                {{ item.analytic.analyticAccount.name }}
              </span>
            </td>
            <td :class="item.cssClass" v-if="showAnalyticUsage && !item.refund">
              <span v-if="item.analytic">
                {{ item.analytic.schoolYear.name }}
              </span>
            </td>
            <td :class="item.cssClass" v-if="showAnalyticUsage && !item.refund">
              <span v-if="item.analytic">
                {{ item.analytic.generalAccount.name }}
              </span>
            </td>
            <td :class="item.cssClass" colspan=2 v-if="showAnalyticUsage && item.refund">
              <div>
                {{ item.refund.refundMode.name }}
                <span v-if="item.refund.bankName">
                  {{ item.refund.bankName }} {{ item.refund.bankNumber }}
                </span>
              </div>
              <div v-if="item.refund.comments">
                <i>{{ item.refund.comments }}</i>
              </div>
            </td>
            <td :class="item.cssClass" class="number" v-if="showAnalyticUsage">
              <span v-if="item.analytic">
                {{ item.analytic.amount | currency }}
              </span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment/moment'
import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import CounterLabel from '@/components/Controls/CounterLabel'
import LoadingGif from '@/components/Controls/LoadingGif'
import Pagination from '@/components/Controls/Pagination'
import DateRangeSelector from '@/components/DateRange/DateRangeSelector'
import PageHeader from '@/components/Layout/PageHeader'
import { makeChoice } from '@/types/base'
import { makeCredit } from '@/types/payments'
import router from '@/router'
import { BackendApi, openDocument } from '@/utils/http'
import { sum } from '@/utils/math'
import { currency } from '@/filters/texts'
import { slugify } from '@/utils/strings'
import { compareNumbers, compareStrings, compareDates, compareDays } from '@/utils/sorting'

export default {
  name: 'Credits',
  mixins: [BackendMixin],
  components: {
    DateRangeSelector,
    Pagination,
    CounterLabel,
    LoadingGif,
    PageHeader,
  },
  data() {
    return {
      loadingName: 'credits',
      credits: [],
      creditsCount: 0,
      page: 0,
      hasNext: false,
      hasPrevious: false,
      initialLoadingDone: false,
      selectedDate: '',
      dates: [],
      creditsStatus: [],
      selectedStatus: makeChoice(),
      init: false,
      startDate: null,
      endDate: null,
      totalAmount: 0,
      totalRemaining: 0,
      noPagination: false,
      showAnalyticSource: true,
      showAnalyticUsage: true,
      orderBy: 0,
      analyticFilter: '',
      dateFilter: 1,
      onlyUsageInDateRange: 1,
    }
  },
  watch: {
    loading: function(newValue, oldValue) {},
    selectedStatus: function(value) {
      this.onFilterChanged(value)
    },
  },
  computed: {
    pagesCount() {
      if (this.noPagination) {
        return 1
      }
      return Math.ceil(this.creditsCount / 100)
    },
    sumSources() {
      if (this.pagesCount === 1) {
        const sumOf = sum(this.items.map(
          itm => itm.fromAnalytic ? +itm.fromAnalytic.amount : 0
        ))
        return currency(sumOf)
      }
      return ''
    },
    sumUsages() {
      if (this.pagesCount === 1) {
        const sumOf = sum(this.items.map(
          itm => itm.analytic ? +itm.analytic.amount : 0
        ))
        return currency(sumOf)
      }
      return ''
    },
    showAnalyticFilter() {
      return (
        (this.showAnalyticSource || this.showAnalyticUsage) &&
        !(this.showAnalyticSource && this.showAnalyticUsage)
      )
    },
    counterLabel() {
      if (this.selectedStatus.id === 0) {
        return 'avoir en cours'
      }
      if (this.selectedStatus.id === 1) {
        return 'avoir utilisé sur facture'
      }
      if (this.selectedStatus.id === 2) {
        return 'remboursement'
      }
      if (this.selectedStatus.id === 3) {
        return 'don'
      }
      return '-'
    },
    counterLabelN() {
      if (this.selectedStatus.id === 0) {
        return 'avoirs en cours'
      }
      if (this.selectedStatus.id === 1) {
        return 'avoirs utilisés sur facture'
      }
      if (this.selectedStatus.id === 2) {
        return 'remboursements'
      }
      if (this.selectedStatus.id === 3) {
        return 'dons'
      }
      return '-'
    },
    items() {
      let items = []
      let counter = 1
      let id = 1
      for (const credit of this.credits) {
        const odd = counter % 2
        let rowspan = 1
        const fromAnalytic = credit.fromAnalytics.length ? credit.fromAnalytics[0] : null
        let startIndex = 0
        let creditItems = []
        if (credit.refund) {
          creditItems.push(
            {
              id: id++,
              credit: credit,
              analytic: credit.refund.asAnalyticDetail(),
              odd: odd,
              rowspan: rowspan,
              fromAnalytic: fromAnalytic,
              obj: credit,
              cssClass: 'refund-usage',
              refund: credit.refund,
            }
          )
        } else {
          startIndex = 1
          creditItems.push(
            {
              id: id++,
              credit: credit,
              analytic: credit.analytics[0],
              odd: odd,
              rowspan: rowspan,
              fromAnalytic: fromAnalytic,
              obj: credit,
              cssClass: 'analytic-usage',
              refund: null,
            }
          )
        }
        if (this.showAnalyticUsage) {
          for (let index = startIndex; index < credit.analytics.length; index++) {
            creditItems.push(
              {
                id: id++,
                credit: (this.showAnalyticUsage && this.showAnalyticSource) ? null : credit,
                analytic: credit.analytics[index],
                odd: odd,
                rowspan: rowspan,
                fromAnalytic: null,
                obj: credit,
                cssClass: 'analytic-usage',
                refund: null,
              }
            )
          }
        }
        if (this.dateFilter === 2 && this.onlyUsageInDateRange === 2) {
          creditItems = creditItems.filter(
            elt => {
              return (
                (!this.startDate || compareDays(elt.analytic.createdOn, this.startDate) >= 0) &&
                (!this.endDate || compareDays(elt.analytic.createdOn, this.endDate) <= 0)
              )
            }
          )
        }
        if (creditItems.length === 0) {
          creditItems.push(
            {
              id: id++,
              credit: credit,
              analytic: null,
              odd: odd,
              rowspan: rowspan,
              fromAnalytic: fromAnalytic,
              obj: credit,
              cssClass: 'analytic-usage',
              refund: null,
            }
          )
        }
        items = items.concat(creditItems.map(
          itm => {
            if (this.showAnalyticUsage && this.showAnalyticSource) {
              itm.rowspan = creditItems.length
            }
            return itm
          }
        ))
        counter += 1
      }
      if (this.pagesCount === 1) {
        if (this.analyticFilter && this.showAnalyticFilter) {
          let analyticFilter = this.analyticFilter.toLowerCase()
          items = items.filter(
            item => {
              let match = false
              if (this.showAnalyticSource) {
                let fromAnalytic = item.fromAnalytic
                if (fromAnalytic) {
                  match = fromAnalytic.analyticAccount.name.toLowerCase().indexOf(analyticFilter) >= 0
                }
              }
              if (this.showAnalyticUsage && !match) {
                let analytic = item.analytic
                if (analytic) {
                  match = analytic.analyticAccount.name.toLowerCase().indexOf(analyticFilter) >= 0
                }
              }
              return match
            }
          )
        }
        items = items.sort(
          (elt1, elt2) => {
            let value = 0
            if (this.orderBy > 0) {
              if (this.orderBy === 2) {
                value = compareStrings(elt1.obj.entity.name, elt2.obj.entity.name)
              }
              if (value === 0) {
                value = compareDates(elt1.obj.createdOn, elt2.obj.createdOn)
              }
            }
            if (value === 0) {
              value = compareNumbers(elt1.id, elt2.id)
            }
            return value
          }
        )
      }
      return items
    },
  },
  mounted() {
    this.creditsStatus = [
      makeChoice({ id: 0, name: 'Avoirs en cours', }),
      makeChoice({ id: 6, name: 'Avoirs utilisés sur facture', }),
      makeChoice({ id: 1, name: 'Avoirs entièrement utilisés', }),
      makeChoice({ id: 2, name: 'Remboursements', }),
      makeChoice({ id: 3, name: 'Dons', }),
      makeChoice({ id: 4, name: 'Chantiers en cours', }),
      makeChoice({ id: 5, name: 'Chantiers utilisés sur facture', })
    ]
    const status = +(this.$route.query ? this.$route.query.status : 0)
    this.onLoaded(status)
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async onLoaded(status) {
      this.init = true
      if (this.$route.query) {
        this.page = +(this.$route.query.page || '1')
      } else {
        this.page = 1
      }

      let statusIndex = 0
      if (status) {
        statusIndex = this.creditsStatus.map(elt => elt.id).indexOf(status)
        statusIndex = (statusIndex < 0) ? 0 : statusIndex
      }
      this.selectedStatus = this.creditsStatus[statusIndex]
      this.startDate = (this.$route.query ? this.$route.query.start : null)
      this.endDate = (this.$route.query ? this.$route.query.end : null)
      this.dateFilter = +(this.$route.query.byDate || '1')
      this.onlyUsageInDateRange = +(this.$route.query.onlyUsage || '1')
      await this.loadElements(this.page, this.selectedStatus.id)
      this.init = false
    },
    onPageChanged(event) {
      this.page = event.page
      this.loadElements(this.page, this.selectedStatus.id)
    },
    reload() {
      this.loadElements(this.page, this.selectedStatus.id)
    },
    onFilterChanged(status) {
      if (!this.init) {
        if (this.$route.query.page !== '1') {
          this.page = 1
          this.$route.query.page = '1'
        }
        let queryArgs = { ...this.$route.query, }
        queryArgs.status = status.id
        queryArgs.start = this.startDate
        queryArgs.end = this.endDate
        queryArgs.byDate = '' + this.dateFilter
        queryArgs.onlyUsage = '' + this.onlyUsageInDateRange
        this.$router.replace({ query: queryArgs, })
        this.loadElements(this.page, status.id)
      }
    },
    getEntityLink(entity) {
      let data
      if (entity.family) {
        data = { name: 'families-detail', params: { entityId: '' + entity.id, }, }
      } else {
        data = { name: 'entities-detail', params: { entityId: '' + entity.id, }, }
      }
      return router.resolve(data).href
    },
    getLinks() {
      return [
        {
          id: 1,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 2,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      ]
    },
    async printMe() {
      const docUrl = '/documents/standard/<key>/pdf/?landscape=1'
      const docSlug = slugify(this.counterLabelN) + moment().format('YYYY-MM-DD-HH-MM-SS')
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = slugify(this.counterLabelN) + moment().format('YYYY-MM-DD-HH-MM-SS')
      const docContent = this.$refs.excelTable.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadElements(page, status) {
      this.startLoading(this.loadingName)
      let url = '/api/credits/?page=' + (page || 1) + '&status=' + (status || 0)
      if (this.startDate) {
        url += ('&start_date=' + this.startDate)
      }
      if (this.endDate) {
        url += ('&end_date=' + this.endDate)
      }
      if (+this.dateFilter !== 1) {
        url += '&usage=1'
      }
      if (this.noPagination) {
        url += '&no_page=1'
      }
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.initialLoadingDone = true
        this.creditsCount = resp.data.count
        this.hasNext = !!resp.data.next
        this.hasPrevious = !!resp.data.previous
        this.credits = resp.data.results.map(elt => makeCredit(elt))
        this.totalAmount = resp.data['total_amount'] || 0
        this.totalRemaining = resp.data['total_remaining_amount'] || 0
      } catch (err) {
        this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    onRefresh() {
      this.loadElements(this.page, this.selectedStatus.id)
    },
    onDateFilterChanged() {
      this.onFilterChanged(this.selectedStatus)
      this.onRefresh()
    },
    onFilterParamsChanged() {
      this.onFilterChanged(this.selectedStatus)
    },
    onDateRangeChanged(event) {
      if (!event.init) {
        this.startDate = event.startDate
        this.endDate = event.endDate
        this.onFilterChanged(this.selectedStatus)
        this.onRefresh()
      }
    },
    reloadPagination(value) {
      this.noPagination = value
      this.reload()
    },
  },
}
</script>

<style scoped lang="less">
.header-line {
  padding: 5px;
  background: #e0e0e0;
  border-bottom: none;
}
.header-label {
  font-size: 1.2em;
  font-weight: bold;
}
tr td .show-on-hover {
  display: none;
}
tr:hover td .show-on-hover {
  display: block;
  font-size: 11px;
}
tr.credit-line.odd td {
  background: #e0e0e0;
}
tr th.analytic-source {
  background: #bae5ff;
}
tr.credit-line td.analytic-source {
  background: #bae5ff;
}
tr.credit-line.odd td.analytic-source {
  background: #9cc2d9;
}
tr th.analytic-usage {
  background: #ffe9bc;
}
tr.credit-line td.analytic-usage {
  background: #ffe9bc;
}
tr.credit-line.odd td.analytic-usage {
  background: #e6d19c;
}
tr th.refund-usage {
  background: #ff6d63;
}
tr.credit-line td.refund-usage {
  background: #f2847c;
}
tr.credit-line.odd td.refund-usage {
  background: #cf706a;
}
th.number, td.number {
  text-align: right;
}
</style>
